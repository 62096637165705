import { useState, useEffect } from 'react';
import {
  getFilesInFolderPaginated,
  getPagesBefore,
  getPagesAfter,
  getPageByKey
} from '../services/dynamoDBService';
import { getSessionToken } from '../services/cognitoService';

export function useFetchPages(folderName, setPages, setLoadingState) {
  const [nextToken, setNextToken] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  // General-purpose fetchPages remains for "load more" functionality
// In your useFetchPages hook (or equivalent)
  const fetchPages = async (token = null, direction = 'next', limit = 10) => {
    const sessionToken = await getSessionToken();
    if (!sessionToken) {
      console.error('No token available for authentication.');
      return;
    }
    setLoadingState({ loading: true });
    try {
      const { files, nextKey } = await getFilesInFolderPaginated(folderName, token, limit);
      setPages(prevPages => [...prevPages, ...files]);
      // If the returned file count equals the limit, assume there might be more pages.
      if (files.length === limit) {
        setNextToken(nextKey || "more");
      } else {
        setNextToken(null);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoadingState({ loading: false });
    }
  };


  // On mount, load a window of pages based on the saved current page.
  useEffect(() => {
    const initialLoad = async () => {
      const savedPageIndexStr = localStorage.getItem(`currentPage_${folderName}`);
      const savedPageKey = localStorage.getItem(`currentPageKey_${folderName}`);

      // If a saved current page exists and it's not the first page...
      if (savedPageIndexStr !== null && parseInt(savedPageIndexStr, 10) > 0 && savedPageKey) {
        // Load the window: previous 3 pages, current page, and next 3 pages
        try {
          const previousPages = await getPagesBefore(folderName, savedPageKey, 3);
          const currentPage = await getPageByKey(folderName, savedPageKey);
          const nextPages = await getPagesAfter(folderName, savedPageKey, 3);
          // Combine them in order
          const combinedPages = [...previousPages];
          if (currentPage) combinedPages.push(currentPage);
          combinedPages.push(...nextPages);
          setPages(combinedPages);
        } catch (error) {
          console.error("Error loading windowed pages:", error);
          // Fallback: load the first 10 pages if window loading fails
          await fetchPages(null, 'next', 10);
        }
      } else {
        // If no saved page exists or it is the first page, load the first 10 pages
        await fetchPages(null, 'next', 10);
      }
      setInitialLoadDone(true);
    };

    if (!initialLoadDone) {
      initialLoad();
    }
  }, [folderName]);

  return { fetchPages, nextToken };
}
