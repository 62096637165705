import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSessionToken } from '../services/cognitoService';
import {
  addFolderAndFiles,
  getAllUserFolders,
  deleteFolderAndContents,
  addFilesToFolder,
} from '../services/dynamoDBService';
import FolderCard from './../components/FolderCard/FolderCard';
import AddFolderCard from './../components/AddFolderCard/AddFolderCard';
import EditFolderModal from './../components/EditFolderModal/EditFolderModal';
import YesNoMessageModal from '../components/YesNoMessageModal/YesNoMessageModal';
import AddFolderModal from '../components/AddFolderModal/AddFolderModal';
import { createS3Items, deleteS3Items } from './../services/s3Service';
import styles from './FolderManager.module.scss';
import { extractNumber, padNumber, extractBaseName } from './../utils/general';

const FolderManager = () => {
  const [folders, setFolders] = useState([]);
  // foldersAdding now holds objects: { name, progress }
  const [foldersAdding, setFoldersAdding] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchS3Items = async () => {
      const token = await getSessionToken();
      if (!token) {
        console.error('Token is not available in localStorage');
        return;
      }
      try {
        const fetchedFolders = await getAllUserFolders();
        setFolders(fetchedFolders);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };

    fetchS3Items();
  }, [refreshTrigger]);

  const handleDeleteButton = (folderName) => {
    setCurrentFolder(folderName);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (currentFolder) {
      await deleteS3Items(currentFolder);
      await deleteFolderAndContents(currentFolder);
      setIsModalOpen(false);
      setFolders(folders.filter((folder) => folder.FolderName !== currentFolder));
    }
  };

  const handleAddFolder = async (folderName, files) => {
    // Add the folder to foldersAdding with initial progress 0
    setFoldersAdding((prev) => [...prev, { name: folderName, progress: 0 }]);

    const paddingWidth = 4;
    // Process files: extract numbers, base name, extension
    const filesWithNumbers = files.map((file) => {
      const originalName = file.name;
      const extension = originalName.substring(originalName.lastIndexOf('.'));
      const number = extractNumber(originalName);
      const baseName = extractBaseName(originalName);
      return { file, originalName, extension, number, baseName };
    });

    filesWithNumbers.sort((a, b) => {
      if (a.number !== null && b.number !== null) {
        return a.number - b.number;
      } else if (a.number !== null) {
        return -1;
      } else if (b.number !== null) {
        return 1;
      } else {
        return a.originalName.localeCompare(b.originalName);
      }
    });

    const adjustedFiles = filesWithNumbers.map((item, idx) => {
      const { file, extension, number, baseName } = item;
      const paddedNumber =
        number !== null ? padNumber(number, paddingWidth) : padNumber(idx + 1, paddingWidth);
      const newName = `${baseName}${paddedNumber}${extension}`;
      return new File([file], newName, { type: file.type });
    });

    try {
      // Upload to S3; update progress to 50%
      await createS3Items(folderName, adjustedFiles);
      setFoldersAdding((prev) =>
        prev.map((f) => (f.name === folderName ? { ...f, progress: 50 } : f))
      );
      // Write to DynamoDB; update progress to 100%
      await addFolderAndFiles(folderName, adjustedFiles);
      setFoldersAdding((prev) =>
        prev.map((f) => (f.name === folderName ? { ...f, progress: 100 } : f))
      );
    } catch (error) {
      console.error('Error adding folder and files:', error);
    } finally {
      setIsAddModalOpen(false);
      // Remove the folder from foldersAdding after a short delay
      setTimeout(() => {
        setFoldersAdding((prev) => prev.filter((f) => f.name !== folderName));
      }, 1000);
      setRefreshTrigger(!refreshTrigger);
    }
  };

  const handleEditButton = (folderName) => {
    setFolderToEdit(folderName);
    setIsEditModalOpen(true);
  };

  const handleAddFilesToFolder = async (folderName, files) => {
    try {
      await createS3Items(folderName, files);
      await addFilesToFolder(folderName, files);
      setIsEditModalOpen(false);
      setRefreshTrigger(!refreshTrigger);
    } catch (error) {
      console.error('Error adding files to folder:', error);
    }
  };

  return (
    <div className={styles.folderManager}>
      <div className={styles.folderGrid}>
        <AddFolderCard onClick={() => setIsAddModalOpen(true)} />
        {folders.map((folder, index) => (
          <FolderCard
            key={index}
            folder={folder}
            isLoading={false}
            onDelete={handleDeleteButton}
            onEdit={handleEditButton}
          />
        ))}
        {foldersAdding.map((folderObj, index) => (
          <FolderCard
            key={`adding-${index}`}
            folder={{ FolderName: folderObj.name }}
            isLoading={true}
            progress={folderObj.progress}
          />
        ))}
      </div>

      <YesNoMessageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
        message="Are you sure you want to delete this folder?"
      />
      <AddFolderModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddFolder={handleAddFolder}
      />
      <EditFolderModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        folderName={folderToEdit}
        onAddFiles={handleAddFilesToFolder}
      />
    </div>
  );
};

export default FolderManager;
