import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchPages } from '../../hooks/useFetchPages';
import { getPagesBefore } from '../../services/dynamoDBService';  // helper for previous pages
import MangaImage from '../MangaImage/MangaImage';
import SideBar from '../SideBar/SideBar';
import './MangaViewer.module.scss';
import HeaderContext from '../../context/HeaderContext';

const MangaViewer = () => {
  const { setHeaderState } = useContext(HeaderContext);
  const { folderName } = useParams();
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedPhrase, setSelectedPhrase] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [loadingState, setLoadingState] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState('width');

  const pageRefs = useRef([]);

  useEffect(() => {
    setHeaderState({
      showZoomButtons: true,
      onZoom: handleZoom,
      onScanAllPages: _scanAllPages,
    });
    return () => {
      setHeaderState({});
    };
  }, []);

  const { fetchPages, nextToken } = useFetchPages(folderName, setPages, setLoadingState);

  const handleZoom = (level) => {
    setZoomLevel(level);
  };

  const handleImageLoad = (imageId) => {
    setPages((prevPages) =>
      prevPages.map((page) => {
        if (page.image_url.split('/').pop() === imageId) {
          const imgElement = document.querySelector(`img[data-image-id="${imageId}"]`);
          if (imgElement) {
            return {
              ...page,
              displayedSize: {
                width: imgElement.clientWidth,
                height: imgElement.clientHeight,
              },
            };
          }
        }
        return page;
      })
    );
  };

  const handlePhraseClick = (phrase) => {
    setSelectedPhrase(phrase);
    setSelectedWord(null);
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleWordClick = (word) => {
    setSelectedWord(word);
  };

  const goBackToPhrases = () => {
    setSelectedWord(null);
  };

  const goBackToPanels = () => {
    setSelectedPhrase(null);
  };

  const goBackToHome = () => {
    navigate('/');
  };

  const _scanAllPages = async () => {
    // Optionally, implement scanning all pages if needed.
  };

  // When the current page changes, update state and store it.
  const handlePageChange = (newPageIndex, page) => {
    setCurrentPageIndex(newPageIndex);
    localStorage.setItem(`currentPage_${folderName}`, newPageIndex);
    if (page && page.folderPageKey) {
      localStorage.setItem(`currentPageKey_${folderName}`, page.folderPageKey);
    }
    console.log(`Set current page: index ${newPageIndex}, key ${page?.folderPageKey}`);
  };

  // This scroll handler finds the page whose center is closest to the viewport center.
  const handleScroll = useCallback(() => {
    let closestIndex = 0;
    let minDistance = Infinity;
    const viewportCenter = window.innerHeight / 2;
    pageRefs.current.forEach((ref, index) => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const elementCenter = rect.top + rect.height / 2;
        const distance = Math.abs(viewportCenter - elementCenter);
        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      }
    });
    if (pages[closestIndex]) {
      handlePageChange(closestIndex, pages[closestIndex]);
    }
  }, [pages, folderName]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // On mount, scroll to the saved page (centering it).
  useEffect(() => {
    const savedPageIndexStr = localStorage.getItem(`currentPage_${folderName}`);
    if (savedPageIndexStr !== null && pages.length > 0) {
      const pageIndex = parseInt(savedPageIndexStr, 10);
      if (pageIndex >= 0 && pageIndex < pages.length) {
        const pageRef = pageRefs.current[pageIndex];
        if (pageRef) {
          const offset = pageRef.offsetTop - (window.innerHeight / 2 - pageRef.clientHeight / 2);
          window.scrollTo({
            top: offset,
            behavior: 'auto',
          });
        }
      }
    }
  }, [pages, folderName]);

  // Load previous 10 pages and prepend them.
  const loadPreviousPages = async () => {
    if (pages.length === 0) return;
    const firstPage = pages[0];
    if (!firstPage || !firstPage.folderPageKey) return;
    try {
      const previousPages = await getPagesBefore(folderName, firstPage.folderPageKey, 10);
      if (previousPages && previousPages.length > 0) {
        setPages(prev => [...previousPages, ...prev]);
      } else {
        console.log("No previous pages available");
      }
    } catch (error) {
      console.error("Error loading previous pages:", error);
    }
  };

  // Determine whether to show the "Load Previous 10 Pages" button.
  // Here we use a heuristic: if the first page's folderPageKey contains "0001" (or matches a similar pattern),
  // then we assume we are at the beginning.
  const firstPageKey = pages[0]?.folderPageKey || "";
  const isAtBeginning = /0001/.test(firstPageKey);

  return (
    <div className="manga-viewer-container">
      <div className="page-container">
        {/* Only show "Load Previous 10 Pages" if we're not at the beginning */}
        {!isAtBeginning && (
          <button onClick={loadPreviousPages} className="load-previous-btn">
            Load Previous 10 Pages
          </button>
        )}
        {pages.map((page, index) => (
          <div
            key={index}
            ref={(el) => (pageRefs.current[index] = el)}
            data-index={index}
          >
            <MangaImage
              page={page}
              handleImageLoad={handleImageLoad}
              handlePhraseClick={handlePhraseClick}
              setSelectedPhrase={setSelectedPhrase}
              zoomLevel={zoomLevel}
            />
          </div>
        ))}
        {nextToken && (
          <button onClick={() => fetchPages(nextToken, 'next')} disabled={loadingState.loading}>
            Load More Pages
          </button>
        )}
      </div>
      <SideBar
        isOpen={sidebarOpen}
        selectedPhrase={selectedPhrase}
        selectedWord={selectedWord}
        goBackToPanels={goBackToPanels}
        goBackToPhrases={goBackToPhrases}
        handleWordClick={handleWordClick}
        onClose={handleCloseSidebar}
      />
      {loadingState.loading && <div>Loading...</div>}
    </div>
  );
};

export default MangaViewer;
