import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectedImage } from './../ProtectedImage/ProtecedImage';
import { CLOUDFRONT_URL } from '../../constants';
import styles from './FolderCard.module.scss';

const FolderCard = ({ folder, isLoading, onDelete, onEdit, progress }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!isLoading) {
      navigate(`/manga/${folder.FolderName}`);
    }
  };

  return (
    <div className={styles.folderCardContainer}>
      <div className={styles.folderCard} onClick={handleNavigate}>
        {folder.CoverImage ? (
          <ProtectedImage
            src={`${CLOUDFRONT_URL}/userData/${folder.UserID}/${folder.CoverImage}`}
            className={styles.folderCoverImage}
          />
        ) : (
          <img src="no_cover.webp" alt="No Cover" className={styles.folderCoverImage} />
        )}
        <div className={styles.folderTitle}>
          <h2>{folder.FolderName}</h2>
        </div>
        {!isLoading && (
          <>
            <img
              src="icons/edit.png"
              alt="Edit"
              className={styles.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(folder.FolderName);
              }}
            />
            <img
              src="icons/bin.png"
              alt="Delete"
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(folder.FolderName);
              }}
            />
          </>
        )}
      </div>
      {isLoading && typeof progress === 'number' && (
        <div className={styles.progressOverlay}>
          <div className={styles.progressBar} style={{ width: `${progress}%` }} />
        </div>
      )}
    </div>
  );
};

export default FolderCard;
