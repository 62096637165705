import awsConfig from '../config/awsConfig';
import { DynamoDBClient, GetItemCommand, PutItemCommand, QueryCommand, DeleteItemCommand, UpdateItemCommand, BatchWriteItemCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { getIdentityId } from "./cognitoService";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { getSessionToken } from './cognitoService';

// Create a DynamoDB Client
const createDynamoDBClient = async () => {
  const token = await getSessionToken();
  return new DynamoDBClient({
    region: awsConfig.region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: awsConfig.region },
      identityPoolId: awsConfig.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.region}.amazonaws.com/${awsConfig.userPoolId}`]: token,
      },
    }),
  });
};

// Retrieve user data from DynamoDB
const getUserData = async () => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserData",
    Key: {
      "UserID": { S: identityId }
    }
  };

  try {
    const { Item } = await dynamoDbClient.send(new GetItemCommand(params));
    if (Item) {
      return unmarshall(Item);
    } else {
      console.log("No data found for the user.");
      return {};
    }
  } catch (error) {
    console.error('Error fetching user data from DynamoDB:', error);
    throw new Error(`Error fetching user data: ${error}`);
  }
};

// Update user data in DynamoDB with optional parameters
const updateUserData = async ({ credits, translationLanguage, offlineMode, termsVersion }) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  let updateExpression = "SET";
  let expressionAttributeNames = {};
  let expressionAttributeValues = {};

  if (credits !== undefined) {
    updateExpression += " #credits = :credits,";
    expressionAttributeNames["#credits"] = "Credits";
    expressionAttributeValues[":credits"] = { N: String(credits) };
  }
  if (translationLanguage !== undefined) {
    updateExpression += " #translationLanguage = :translationLanguage,";
    expressionAttributeNames["#translationLanguage"] = "TranslationLanguage";
    expressionAttributeValues[":translationLanguage"] = { S: translationLanguage };
  }
  if (offlineMode !== undefined) {
    updateExpression += " #offlineMode = :offlineMode,";
    expressionAttributeNames["#offlineMode"] = "OfflineMode";
    expressionAttributeValues[":offlineMode"] = { BOOL: offlineMode };
  }
  if (termsVersion !== undefined) {
    updateExpression += " #termsVersion = :termsVersion,";
    expressionAttributeNames["#termsVersion"] = "TermsVersion";
    expressionAttributeValues[":termsVersion"] = { S: termsVersion };
  }

  // Remove trailing comma
  if (updateExpression.endsWith(',')) {
    updateExpression = updateExpression.slice(0, -1);
  }

  if (Object.keys(expressionAttributeNames).length === 0) {
    throw new Error("No attributes provided for update.");
  }

  const params = {
    TableName: "UserData",
    Key: { "UserID": { S: identityId } },
    UpdateExpression: updateExpression,
    ExpressionAttributeNames: expressionAttributeNames,
    ExpressionAttributeValues: expressionAttributeValues
  };

  try {
    await dynamoDbClient.send(new UpdateItemCommand(params));
    console.log("User data updated successfully.");
  } catch (error) {
    console.error('Error updating user data in DynamoDB:', error);
    throw new Error(`Error updating user data: ${error}`);
  }
};

// Retrieve all user folders
const getAllUserFolders = async () => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    KeyConditionExpression: "UserID = :userId",
    FilterExpression: "IsFolder = :isFolder",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":isFolder": { S: "true" }
    }
  };

  try {
    const { Items } = await dynamoDbClient.send(new QueryCommand(params));
    return Items.map(item => unmarshall(item));
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw new Error(`Error fetching folders: ${error.message || error}`);
  }
};

// Retrieve all files (pages) in a specific folder (non-paginated)
// Uses the new GSI ("FolderPageIndex") and relies on the folderPageKey attribute.
const getFilesInFolder = async (folderName) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    IndexName: "FolderPageIndex", // Use the new GSI
    KeyConditionExpression: "UserID = :userId and begins_with(folderPageKey, :folderPrefix)",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":folderPrefix": { S: `${folderName}#` }
    },
    ScanIndexForward: true
  };

  try {
    const { Items } = await dynamoDbClient.send(new QueryCommand(params));
    return Items.map(item => unmarshall(item));
  } catch (error) {
    console.error('Error fetching files in folder:', error);
    throw new Error(`Error fetching files in folder: ${error.message || error}`);
  }
};

// Retrieve files with pagination using the FolderPageIndex
const getFilesInFolderPaginated = async (folderName, startKey = null, limit = 10) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    IndexName: "FolderPageIndex",
    KeyConditionExpression: "UserID = :userId and begins_with(folderPageKey, :folderPrefix)",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":folderPrefix": { S: `${folderName}#` }
    },
    Limit: limit,
    ExclusiveStartKey: startKey,
    ScanIndexForward: true
  };

  try {
    const response = await dynamoDbClient.send(new QueryCommand(params));
    const files = response.Items.map(item => unmarshall(item));
    console.log("Files in folder retrieved:", files);
    return {
      files,
      nextKey: response.LastEvaluatedKey
    };
  } catch (error) {
    console.error('Error fetching files in folder:', error);
    throw new Error(`Error fetching files in folder: ${error.message || error}`);
  }
};

// Add a new folder
const addNewFolder = async (folderName, coverImage) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    Item: {
      "UserID": { S: identityId },
      "ItemPath": { S: folderName },
      "FolderName": { S: folderName },
      "IsFolder": { S: "true" },
      "CreatedOn": { S: new Date().toISOString() },
      ...(coverImage && { "CoverImage": { S: coverImage } })
    }
  };

  try {
    await dynamoDbClient.send(new PutItemCommand(params));
    console.log("Folder created successfully.");
  } catch (error) {
    console.error('Error creating new folder:', error);
    throw new Error(`Error creating new folder: ${error.message || error}`);
  }
};
//
//async function batchWriteWithRetry(dynamoDbClient, params, maxRetries = 5) {
//  let retries = 0;
//  let unprocessedItems = params.RequestItems;
//
//  do {
//    const response = await dynamoDbClient.send(new BatchWriteItemCommand({ RequestItems: unprocessedItems }));
//    unprocessedItems = response.UnprocessedItems;
//    if (unprocessedItems && Object.keys(unprocessedItems).length > 0) {
//      retries++;
//      const delay = Math.pow(2, retries) * 100; // exponential backoff: 200ms, 400ms, 800ms, etc.
//      console.log(`Retrying unprocessed items, attempt ${retries}. Waiting ${delay}ms.`);
//      await new Promise(resolve => setTimeout(resolve, delay));
//    } else {
//      break;
//    }
//  } while (retries < maxRetries);
//
//  if (unprocessedItems && Object.keys(unprocessedItems).length > 0) {
//    throw new Error("Failed to process all batch write items after retries");
//  }
//}

const addFilesToFolder = async (folderName, files) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();

  // Create a list of PutRequest objects for each file.
  const putRequests = files.map(file => ({
    PutRequest: {
      Item: {
        "UserID": { S: identityId },
        "FolderName": { S: folderName },
        "ItemPath": { S: `${folderName}/${file.name}` },
        "folderPageKey": { S: `${folderName}#${file.name}` },
        "IsFolder": { S: "false" },
        "CreatedOn": { S: new Date().toISOString() }
      }
    }
  }));

  // DynamoDB BatchWriteItem supports up to 25 items per request.
  const BATCH_SIZE = 25;
  for (let i = 0; i < putRequests.length; i += BATCH_SIZE) {
    const batch = putRequests.slice(i, i + BATCH_SIZE);
    const params = {
      RequestItems: {
        "UserFiles": batch
      }
    };

    try {
      await batchWriteWithRetry(dynamoDbClient, params);
      console.log(`Batch starting at index ${i} processed successfully.`);
    } catch (error) {
      console.error(`Error processing batch starting at index ${i}:`, error);
      throw new Error(`Error processing batch: ${error.message || error}`);
    }
  }
};



// Add a new folder and files to it
const addFolderAndFiles = async (folderName, files) => {
  const coverImage = files.length > 0 ? `${folderName}/${files[0].name}` : null;
  await addNewFolder(folderName, coverImage);
  await addFilesToFolder(folderName, files);
};

// Helper for batch write with retry (for file deletions)
async function batchWriteWithRetry(dynamoDbClient, params, maxRetries = 10) {
  let retries = 0;
  let unprocessedItems = params.RequestItems;

  do {
    const response = await dynamoDbClient.send(new BatchWriteItemCommand({ RequestItems: unprocessedItems }));
    unprocessedItems = response.UnprocessedItems;
    if (unprocessedItems && Object.keys(unprocessedItems).length > 0) {
      retries++;
      const delay = Math.pow(2, retries) * 500; // increased base delay: 500ms, 1000ms, 2000ms, etc.
      console.log(`Retrying unprocessed batch items (attempt ${retries}). Waiting ${delay}ms.`);
      await new Promise(resolve => setTimeout(resolve, delay));
    } else {
      break;
    }
  } while (retries < maxRetries);

  if (unprocessedItems && Object.keys(unprocessedItems).length > 0) {
    throw new Error("Failed to process all batch delete items after retries");
  }
}

// Helper for a single delete operation with retries
async function retryDeleteItem(dynamoDbClient, params, maxRetries = 10) {
  let retries = 0;
  while (retries < maxRetries) {
    try {
      const response = await dynamoDbClient.send(new DeleteItemCommand(params));
      return response;
    } catch (error) {
      if (
        error.name === 'ProvisionedThroughputExceededException' ||
        error.name === 'ThrottlingException'
      ) {
        retries++;
        const delay = Math.pow(2, retries) * 500; // increased delay factor
        console.log(`Retrying delete item (attempt ${retries}). Waiting ${delay}ms.`);
        await new Promise(resolve => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  }
  throw new Error("Max retries exceeded for delete item");
}

const deleteFolderAndContents = async (folderName) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const files = await getFilesInFolder(folderName);

  // Prepare delete requests for each file
  const deleteRequests = files.map(file => ({
    DeleteRequest: {
      Key: {
        "UserID": { S: identityId },
        "ItemPath": { S: file.ItemPath }
      }
    }
  }));

  const BATCH_SIZE = 25;
  for (let i = 0; i < deleteRequests.length; i += BATCH_SIZE) {
    const batch = deleteRequests.slice(i, i + BATCH_SIZE);
    const params = {
      RequestItems: {
        "UserFiles": batch
      }
    };
    console.log("Deleting batch of files with params:", params);
    try {
      await batchWriteWithRetry(dynamoDbClient, params);
      console.log(`Batch starting at index ${i} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting batch starting at index ${i}:`, error);
      throw new Error(`Error deleting batch: ${error.message || error}`);
    }
    // Add a small delay between batches to avoid overwhelming throughput
    await new Promise(resolve => setTimeout(resolve, 500));
  }

  // Delete the folder item itself with retries
  const folderParams = {
    TableName: "UserFiles",
    Key: {
      "UserID": { S: identityId },
      "ItemPath": { S: folderName }
    }
  };
  console.log("Deleting folder with params:", folderParams);

  try {
    await retryDeleteItem(dynamoDbClient, folderParams);
    console.log("Folder deleted successfully.");
  } catch (error) {
    console.error('Error deleting folder:', error);
    throw new Error(`Error deleting folder: ${error.message || error}`);
  }
};


// Fetch pages before the given page key (in reverse order, then reverse back to ascending)
// In your dynamoDBService.js
const getPagesBefore = async (folderName, currentPageKey, limit = 10) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    IndexName: "FolderPageIndex",
    KeyConditionExpression: "UserID = :userId and folderPageKey < :currentPageKey",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":currentPageKey": { S: currentPageKey },
    },
    Limit: limit,
    ScanIndexForward: false, // descending order so that we get the latest ones before the current
  };

  const response = await dynamoDbClient.send(new QueryCommand(params));
  // Reverse to restore ascending order before returning
  return response.Items.map(item => unmarshall(item)).reverse();
};


// Fetch pages after the given page key (in ascending order)
const getPagesAfter = async (folderName, currentPageKey, limit = 3) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    IndexName: "FolderPageIndex",
    KeyConditionExpression: "UserID = :userId and folderPageKey > :currentPageKey",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":currentPageKey": { S: currentPageKey }
    },
    Limit: limit,
    ScanIndexForward: true
  };

  const response = await dynamoDbClient.send(new QueryCommand(params));
  return response.Items.map(item => unmarshall(item));
};

// Fetch the page that exactly matches the provided key
const getPageByKey = async (folderName, currentPageKey) => {
  const identityId = await getIdentityId();
  const dynamoDbClient = await createDynamoDBClient();
  const params = {
    TableName: "UserFiles",
    IndexName: "FolderPageIndex",
    KeyConditionExpression: "UserID = :userId and folderPageKey = :currentPageKey",
    ExpressionAttributeValues: {
      ":userId": { S: identityId },
      ":currentPageKey": { S: currentPageKey }
    }
  };

  const response = await dynamoDbClient.send(new QueryCommand(params));
  if (response.Items && response.Items.length > 0) {
    return unmarshall(response.Items[0]);
  }
  return null;
};


export {
  getUserData,
  updateUserData,
  getAllUserFolders,
  getFilesInFolder,
  getFilesInFolderPaginated,
  addNewFolder,
  addFilesToFolder,
  addFolderAndFiles,
  deleteFolderAndContents,
  getPagesBefore,
  getPagesAfter,
  getPageByKey
};

